import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";

const LandingBanner = () => {
  return (
    <>
      <div className="landing-banner">
        <Container>
          <Row>
            <Col md={7}>
              <div className="banner-content">
                <h1>
                  AutoBot is an AI chatbox design to help technicians fix
                  vehicle faster
                </h1>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.{" "}
                </p>
                <Link className="btnwhite" to="">
                  Try it <BsArrowUpRight />
                </Link>
              </div>
            </Col>
            <Col md={5}>
              <div className="banner-image">
                <img src="/images/banner-login.png" alt="banner image" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LandingBanner;
