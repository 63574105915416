import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Carousel } from "react-bootstrap";

const TestimonialSlider = () => {
  return (
    <>
      <Row>
        <Col lg={3} md={6}>
          <div className="user-review-slider">
            <img src="/images/Review.svg" alt="stars" />
            <p>
              Moderate children at of outweigh it. Unsatiable it considered
              invitation he travelling insensible. Consulted admitting oh mr up
              as described.
            </p>
            <div className="userdetail">
              <img src="images/testimonialuser.svg" alt="user" />
              <div className="username">
                <h4>Robert Fox</h4>
                <p>New York City, NY</p>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={3} md={6}>
          <div className="user-review-slider">
            <img src="/images/Review.svg" alt="stars" />
            <p>
              Moderate children at of outweigh it. Unsatiable it considered
              invitation he travelling insensible. Consulted admitting oh mr up
              as described.
            </p>
            <div className="userdetail">
              <img src="images/testimonialuser.svg" alt="user" />
              <div className="username">
                <h4>Robert Fox</h4>
                <p>New York City, NY</p>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={3} md={6}>
          <div className="user-review-slider">
            <img src="/images/Review.svg" alt="stars" />
            <p>
              Moderate children at of outweigh it. Unsatiable it considered
              invitation he travelling insensible. Consulted admitting oh mr up
              as described.
            </p>
            <div className="userdetail">
              <img src="images/testimonialuser.svg" alt="user" />
              <div className="username">
                <h4>Robert Fox</h4>
                <p>New York City, NY</p>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={3} md={6}>
          <div className="user-review-slider">
            <img src="/images/Review.svg" alt="stars" />
            <p>
              Moderate children at of outweigh it. Unsatiable it considered
              invitation he travelling insensible. Consulted admitting oh mr up
              as described.
            </p>
            <div className="userdetail">
              <img src="images/testimonialuser.svg" alt="user" />
              <div className="username">
                <h4>Robert Fox</h4>
                <p>New York City, NY</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TestimonialSlider;
