import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";

const PrimaryHeader = () => {
  return (
    <>
      <div className="PrimaryHeader">
        <Container>
          <Row>
            <Navbar className="d-flex align-items-center justify-content-between p-0">
                <Navbar.Brand to='/'>
                    <img src='/images/logo.png' alt='logo'/>
                </Navbar.Brand>
                <Link className='btnprimary' to="login">Contact us</Link>
            </Navbar>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PrimaryHeader;
