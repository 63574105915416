import React, { useEffect } from 'react';
import PrimaryHeader from '../../Components/Layout/Headers/PrimaryHeader';
import AboutPoe from '../../Components/Layout/LandingLayout/AboutPoe';
import BeforeFooter from '../../Components/Layout/LandingLayout/BeforeFooter';
import Benefitpage from '../../Components/Layout/LandingLayout/Benefits';
import LandingFooter from '../../Components/Layout/LandingLayout/Footer';
import HowWeWork from '../../Components/Layout/LandingLayout/HowWeWork';
import LandingBanner from '../../Components/Layout/LandingLayout/LandingBanner';
import Logos from '../../Components/Layout/LandingLayout/LogosWrapper/Logos';
import Testimonial from '../../Components/Layout/LandingLayout/Testimonial';

const Home = (props) => {
    
    return (
        <>
            <div className="homebar">
                <PrimaryHeader/>
                <LandingBanner/>
                <Logos/>
                <AboutPoe/>
                <HowWeWork/>
                <Testimonial/>
                <Benefitpage/>
                <BeforeFooter/>
                <LandingFooter/>
            </div>
        </>
    );
}

export default Home;