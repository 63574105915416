import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineFileDone } from "react-icons/ai";
import { GiPencilRuler } from "react-icons/gi";
import { FiUsers } from "react-icons/fi";

const Benefitpage = () => {
  return (
    <>
      <div className="Benefits-wrapper">
        <Container>
          <div className="titlecenterbar">
            <div className="title relative">
              <h4>Benefits of Using Poe</h4>
              <h2 className="shaper">Benefits of Poe</h2>
            </div>
            <div className="benefits-box-wrapper">
              <Row>
                <Col lg={4}>
                  <div className="benefits-box">
                    <AiOutlineFileDone />
                    <h3>Customize with ease</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua ut enim.
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="benefits-box">
                    <GiPencilRuler />
                    <h3>Perfectly Responsive</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua ut enim.
                    </p>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="benefits-box">
                    <FiUsers />
                    <h3>Friendly Support</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua ut enim.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Benefitpage;
