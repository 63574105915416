import React from "react";
import { Container } from "react-bootstrap";

const Logos = () => {
  return (
    <>
      <div className="logos-wrapper">
        <Container>
          <ul className="Logos-slider">
            <li>
              <img src="/images/Entrepreneur.svg" alt="logo1" />
            </li>
            <li>
              <img src="/images/Google.svg" alt="logo2" />
            </li>
            <li>
              <img src="/images/Forbes.svg" alt="logo3" />
            </li>
            <li>
              <img src="/images/Microsoft.svg" alt="logo4" />
            </li>
            <li>
              <img src="/images/Stripe.svg" alt="logo5" />
            </li>
            <li>
              <img src="/images/Uber.svg" alt="logo6" />
            </li>
          </ul>
        </Container>
      </div>
    </>
  );
};

export default Logos;
