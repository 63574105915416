import { useEffect } from 'react';
import '../src/Css/style.css';
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import SupplierLayout from './Components/Layout/SupplierLayout';
import Home from './Pages/Home';
import LandingPageLayout from './Components/Layout/LandingLayout';
import Login from './Pages/Auth/Login';
import OTP from './Pages/Auth/OTP';


function AppRouter() {
  
  const history = useNavigate();
  useEffect(() => {
    document.body.setAttribute("data-page", window?.location?.pathname);
  }, [history]);
  
  return (
    <>

      <Routes>

        <Route element={<LandingPageLayout/>}>
          <Route exact="true" path='/' element={<Home/>} />
        </Route>

        <Route element={<SupplierLayout/>}>
          <Route exact="true" path='/login'element={<Login/>}  />
          <Route exact="true" path='/otp'element={<OTP/>}  />
        </Route>

      </Routes>
    </>
  );
}

export default AppRouter;
