import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const AboutPoe = () => {
  return (
    <>
      <div className="AboutPoe-wrapper">
        <Container>
          <Row>
            <Col lg={7}>
              <div className="title">
                <h4>About Poe</h4>
                <h2 className="shaper">About Poe</h2>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. It is a long established fact that a reader will be distracted by the readable content.</p>
              </div>
            </Col>
            <Col lg={5}>
              <div className="about-img">
                <img src='/images/robot.svg' alt='aboutimg'/>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AboutPoe;
