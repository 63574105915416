import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const LandingFooter = () => {
  return (
    <>
      <div className="LandingFooter">
        <Container>
            <div className="title">
              <Link><img src='/images/logowhite.svg' alt='whitelogo'/></Link>
            </div>
            <ul>
              <li><Link>Privacy policy</Link></li>
              <li><Link>Terms & Conditions</Link></li>
              <li><Link>Contact Us</Link></li>
              
            </ul>
        </Container>
      </div>
    </>
  );
};

export default LandingFooter;
