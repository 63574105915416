import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { AiFillApple } from "react-icons/ai";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

const Login = () => {
  const [value, setValue] = useState();

  return (
    <>
      <div className="landing-banner loginbar">
        <div className="formbar">
          <img src="/images/logo.png" alt="icon" />
          <h2>Fast, helpful AI chat</h2>
          <PhoneInput
            international
            countryCallingCodeEditable={false}
            defaultCountry="US"
            value={value}
            onChange={setValue}
          />
          <p>We use your phone for verification during each sign in.</p>
          <div className="btnbar">
            <Link className="btnprimary w-100" to='/otp' type="submit">Login</Link>
            <Link className="btnunfill w-100 d-block" to="">
              Use email
            </Link>
          </div>
          <div class="orbar">
            <hr class="or-line" />
            <div class="or-text">or</div>
            <hr class="or-line" />
          </div>
          <div className="btnbar2">
            <Link className="btnprimary" type="submit">
              <FcGoogle /> Continue with Google
            </Link>
            <Link className="btnunfill" to="">
              <AiFillApple /> Continue with Apple
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
