import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TestimonialSlider from "./TestimonialSlider";

const Testimonial = () => {
  return (
    <>
      <div className="AboutPoe-wrapper">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="title">
                <h4>Testimonials</h4>
                <h2 className="shaper">Testimonials</h2>
              </div>
            </Col>
            
          </Row>
        </Container>
        <div className="sliderbar">
          <TestimonialSlider/>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
