import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const BeforeFooter = () => {
  return (
    <>
      <div className="beforefoot-wrapper">
        <Container>
            <div className="title">
              <h2>Fix your vehicle efficiently with the help of our ChatBots</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
              <Link className='btnprimary'>Try it <BsArrowUpRight /></Link>
            </div>
        </Container>
      </div>
    </>
  );
};

export default BeforeFooter;
