import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";

const OTP = () => {
  const [otp, setOtp] = useState("");

  return (
    <>
      <div className="landing-banner loginbar">
        <div className="formbar">
          <img src="/images/logo.png" alt="icon" />
          <label className="labelbar">Enter your OTP</label>
          <div className="otpbar">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <Link className="btnprimary w-100" to="/otp" type="submit">
            Submit
          </Link>
        </div>
      </div>
    </>
  );
};

export default OTP;
