
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillFileAdd } from "react-icons/ai";

const HowWeWork = () => {
  return (
    <>
      
      <div className="HowWeWork-wrapper">
        <Container>
          <div className="HowWeWork-wrapperinner titlecenterbar">
            <div className="title relative">
              <h4>How We Work</h4>
              <h2 className="shaper">How We Work</h2>
            </div>
            <Row>
              <Col lg={7}>
                <ul className="HowWeWork-boxes">
                  <li>
                    <div className="iconbox">
                      <div className="iconbar">
                        <AiFillFileAdd />
                      </div>
                      <h5>Lorem</h5>
                    </div>
                    <div className="contentbar">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. typesetting industry.{" "}
                        </p>
                      </div>
                  </li>
                  <li>
                    <div className="iconbox">
                      <div className="iconbar">
                        <AiFillFileAdd />
                      </div>
                      <h5>Lorem</h5>
                    </div>
                    <div className="contentbar">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. typesetting industry.{" "}
                        </p>
                      </div>
                  </li>
                  <li>
                    <div className="iconbox">
                      <div className="iconbar">
                        <AiFillFileAdd />
                      </div>
                      <h5>Lorem</h5>
                    </div>
                    <div className="contentbar">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. typesetting industry.{" "}
                        </p>
                      </div>
                  </li>
                </ul>
              </Col>
              <Col lg={5}>
                <div className="about-img">
                  <img src="/images/work.svg" alt="aboutimg" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default HowWeWork;
